<template>
  <OtherBanner :type="14" />

  <section class="filter-box" id="pageTop">
    <div class="content">
      <!-- 分类 -->
      <TreeFilter
        :name="$t('category')"
        :dataSource="folderData"
        @onChange="folderChange"
      />
      <!-- 状态 -->
      <ListFilter
        :name="$t('CM_Status')"
        :dataSource="packageStatus"
        @onChange="statusChange"
      />
    </div>
  </section>

  <section class="toolbar">
    <div class="content">
      <div class="crumbs">
        {{ $t("current_position") }}：
        <!-- 当前位置： -->
        <span
          v-for="(item, index) in folderCrumbs"
          :key="item.id"
          @click="crumbsClick(item, index)"
        >
          {{ item.name }}
          <i>></i>
        </span>
      </div>
      <div class="search">
        <div class="search-icon" @click="search">
          <SearchOutlined style="color: #656565" />
        </div>
        <a-input
          class="search-input"
          v-model:value.trim="searchVal"
          :placeholder="$t('please_enter_keywords')"
          allow-clear
          @pressEnter="search"
        />
        <!-- 请输入关键字 -->
        <!-- @change="!searchVal && getPackageList()" -->
      </div>
    </div>
  </section>

  <section class="course">
    <div class="content">
      <a-spin :spinning="courseLoading">
        <template v-if="courseData.length">
          <div class="cards">
            <div class="item" v-for="item in courseData" :key="item.courseId">
              <PackageCard :dataSource="item" />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :defaultPageSize="20"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import TreeFilter from "@/components/filter/TreeFilter.vue";
import ListFilter from "@/components/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import PackageCard from "@/components/package/PackageCard.vue";
import { packageList, packageFolder } from "@/api/package";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    TreeFilter,
    ListFilter,
    OtherBanner,
    PackageCard,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    // 目录
    let folderId = 0;
    let folderCrumbs = ref([
      {
        id: 0,
        name: $t("CM_All"),
        // 全部
      },
    ]);
    let folderData = ref([]);
    packageFolder().then((res) => {
      if (res.ret === 0) {
        folderData.value = res.data || [];
      }
    });
    const folderChange = ({ activeId, crumbs }) => {
      folderId = activeId;
      folderCrumbs.value = crumbs;
      getPackageList();
    };

    // 格式
    let statusActive = [0];
    // 项目状态
    const packageStatus = [
      {
        name: $t("package.not_purchased"), // 未购买
        id: 1,
      },
      {
        name: $t("package.purchased"), // 已购买
        id: 2,
      },
    ];
    const statusChange = (arr) => {
      statusActive = arr;
      getPackageList();
    };

    // 排序
    const orderActive = ref(1),
      orderChange = (status) => {
        orderActive.value = status;
        getPackageList();
      };

    // 面包屑
    const crumbsClick = (data, index) => {
      let dom = document.getElementById(`tree_${index}_${data.id}`);
      dom.click();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getPackageList();
      };

    // 培训包
    const courseData = ref([]),
      courseLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getPackageList = (scroll = false) => {
        courseLoading.value = true;
        packageList({
          page: currentPage.value,
          pageSize: 20,
          name: searchVal.value,
          folderId: folderId,
          order: orderActive.value,
          status: statusActive[0] === 0 ? [] : statusActive,
        }).then((res) => {
          courseLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            courseData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getPackageList(true);
      };
    getPackageList();

    return {
      folderData,
      folderCrumbs,
      folderChange,
      crumbsClick,
      packageStatus,
      statusChange,
      orderActive,
      orderChange,
      searchVal,
      search,
      getPackageList,
      courseData,
      courseLoading,
      currentPage,
      pageTotal,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.filter-box {
  background-color: @color-page-gray;
  padding-bottom: 14px;
  .content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
    .mixinWrap();
    .order-box {
      height: 34px;
      .mixinFlex(flex-end; center);
      a {
        font-size: 14px;
        color: #666;
        margin-left: 26px;
        span {
          padding-right: 4px;
        }
        &:hover,
        &.active {
          color: @color-theme;
        }
      }
    }
  }
}

.toolbar {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    .mixinFlex(space-between; center);
    padding: 20px 0 16px;
    .crumbs {
      width: calc(100% - 250px);
      font-size: 14px;
      color: #999;
      span {
        cursor: pointer;
        i {
          padding: 0 2px;
        }
        &:hover {
          color: @color-theme;
        }
        &:last-child {
          .noevents();
          i {
            display: none;
          }
          &:hover {
            color: #999;
          }
        }
      }
    }
    .search {
      border: 1px solid #ccc;
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
}

.course {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      .item {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
