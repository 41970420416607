<template>
  <div class="card">
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
    </div>
    <div class="content">
      <div class="tag">
        <span v-if="dataSource.isBuy">{{ $t("package.purchased") }}</span>
        <!-- 已购买 -->
      </div>
      <h5>{{ dataSource.taskName }}</h5>
      <p>
        {{ $t("package.personal_purchase", 2) }}：
        <!-- 个人购买： -->
        <strong v-if="dataSource.price1 > 0">
          {{ $t("currency_symbol") }}{{ dataSource.price1 }}
        </strong>
        <strong v-else>{{ $t("LB_NotCharge") }}</strong>
        <!-- 免费 -->
      </p>
      <p v-if="useEnterprise == 1 && dataSource.price2 > 0">
        {{ $t("package.enterprise_purchase", 2) }}：<strong
          >{{ $t("currency_symbol") }} {{ dataSource.price2 }}</strong
        >
        <!-- 单位购买： -->
        <i>
          {{ $t("package.min_buy") }}&nbsp;
          <!-- 单位最低购买 -->
          <strong>{{ dataSource.minPays }}</strong>
          {{ $t("LB_My_Ge") }}
        </i>
      </p>
      <p>
        <!-- 有效期： -->
        {{ $t("validity_period") }}：<strong
          >{{ dataSource.effective }}
          <template v-if="dataSource.dateType == 1">{{
            $t("Pub_Day")
          }}</template>
          <!-- 天 -->
          <template v-if="dataSource.dateType == 2">{{
            $t("order.month")
          }}</template>
          <!-- 个月 -->
          <template v-if="dataSource.dateType == 3">{{
            $t("CM_Year")
          }}</template>
          <!-- 年 -->
        </strong>
      </p>
      <div class="bottom">
        <router-link
          class="link"
          :to="'/package/introduce?id=' + dataSource.taskId"
        >
          {{ $t("view_detail") }} <RightOutlined />
          <!-- 查看详情 -->
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "PackageCard",
  props: {
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const store = useStore();

    let useEnterprise = computed(
      () => store.getters.platformConfig.useEnterprise
    );

    return {
      useEnterprise,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  height: 300px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  background-color: #fff;
  transition: all 0.3s ease;
  position: relative;
  .mixinFlex(space-between);
  .cover {
    .mixinImgWrap(661px; 300px);
    border-radius: 8px 0px 0px 8px;
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      background-color: rgba(255, 255, 255, 0);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease;
    }
  }
  .content {
    width: calc(100% - 661px);
    padding: 20px;
    .tag {
      height: 30px;
      margin-bottom: 10px;
      span {
        width: 60px;
        height: 30px;
        display: block;
        color: #fff;
        background-color: @color-theme;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
      }
    }
    h5 {
      font-size: 22px;
      .mixinEllipsis(68px, 2);
      margin: 0;
    }
    p {
      color: #666;
      font-size: 20px;
      line-height: 30px;
      margin: 5px 0;
      strong {
        color: #e63450;
        font-weight: normal;
      }
      i {
        font-size: 14px;
        padding-left: 2px;
      }
    }
    .bottom {
      text-align: right;
      .link {
        display: inline-block;
        color: #fff;
        font-size: 14px;
        width: 106px;
        height: 40px;
        background-color: @color-theme;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        line-height: 40px;
        text-align: center;
      }
    }
  }
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
    .cover {
      &::after {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
